import React from "react"
import { useStateContext } from "../../shared/state-context"
import useFormist from "react-formist"
import * as yup from "yup"
import Form from "../shared/components/form"
import TextField from "../shared/components/textfield"
import { mkApi } from "./api"
import { success } from "../../shared/notifications"

import withFetch from "../shared/components/fetch"
import { analysisTypes } from "../../analysisTypes"
import { MultiSelectField, SelectField } from "../shared/components/selectfield"

const schema = yup.object().shape({
    id: yup.string().required(),
    name: yup.string().required(),
    analysisType: yup.string().required(),
    analysisSubType: yup.string(),
    language: yup.string(),
    referralCodes: yup.array().of(yup.string().required()).required(),
    defaultLayout: yup.boolean().required(),
})

export const Edit = ({ data, history }) => {
    const [layout, referrals] = data[0]
    const { dispatch } = useStateContext()
    const api = mkApi(dispatch)

    const formist = useFormist(layout, {
        schema,
        onSubmit: (layout) => {
            api.update(layout).then((response) => {
                if (response && response.ok) success("Layout saved!")
            })
        },
    })

    const referralsOptions = referrals.items.map((x) => {
        return {
            value: x.code,
            label: `${x.name}`,
        }
    })

    const currentAnalysisType = analysisTypes.find(
        (a) => a.value === formist.getFieldProps("analysisType").value,
    )

    const analysisSubTypes = [
        {
            label: " - None - ",
            value: "Unknown",
        },
        ...(currentAnalysisType?.subTypes || []),
    ]

    return (
        <>
            <Form
                size="full"
                title="Edit Layout"
                {...formist.getFormProps()}
                primaryText="Save"
                onCancel={() => history.goBack()}>
                <input type="hidden" {...formist.getFieldProps("id")} />
                <div className="form-row">
                    <TextField
                        label="Name"
                        width="col"
                        {...formist.getFieldProps("name")}
                    />
                </div>
                <div className="form-row">
                    <SelectField
                        label="Analysis Type"
                        width="col"
                        options={analysisTypes}
                        {...formist.getFieldProps("analysisType")}
                    />
                    {analysisSubTypes.length > 1 ? (
                        <SelectField
                            label="Analysis Sub Type"
                            width="col"
                            options={analysisSubTypes}
                            {...formist.getFieldProps("analysisSubType")}
                        />
                    ) : null}
                </div>
                <div className="form-row">
                    <TextField
                        label="Language"
                        width="col"
                        {...formist.getFieldProps("language")}
                    />
                </div>
                <div className="form-row">
                    <MultiSelectField
                        label="Referrals"
                        width="col"
                        {...formist.getFieldProps("referralCodes")}
                        options={referralsOptions}
                    />
                </div>
            </Form>
        </>
    )
}

const loadData = ({ api, id }) => {
    return api.load(id)
}

const loadReferrals = ({ api }) => {
    return api.loadReferrals()
}

export default withFetch(Edit, mkApi(), loadData, undefined, undefined, [
    loadReferrals,
])
