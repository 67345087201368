import { apis } from "../../shared/apis"
import { actions } from "./store"

export function mkApi(dispatch) {
    return {
        loadBacteria() {
            return apis.bacteria.loadAll(1, 1000)
        },
        loadPathogens() {
            return apis.pathogens.loadAll(1, 1000)
        },
        loadDrugs() {
            return apis.drugs.loadAll(1, 1000)
        },
        loadHormones() {
            return apis.hormones.loadAll(1, 1000)
        },
        loadTemplates() {
            return apis.templates.loadAll(1, 1000)
        },
        loadReferrals() {
            return apis.referrals.loadAll(1, 1000)
        },
        loadAll(pageNumber, pageSize) {
            return apis.templates
                .loadAll(pageNumber, pageSize)
                .then((templates) => dispatch(actions.loadAll(templates)))
        },
        loadFiltered(filter, pageNumber, pageSize) {
            apis.templates
                .search(filter.analysisType || "", pageNumber, pageSize)
                .then((templates) =>
                    dispatch(actions.loadFiltered(templates, filter)),
                )
        },
        load(id) {
            return apis.templates.load(id)
        },
        add(value) {
            return apis.templates.addOne(value)
        },
        update(value) {
            const { id, ...body } = value
            return apis.templates
                .updateOne(id, removeGuiFields(body))
                .then((response) => {
                    dispatch(actions.update(value))
                    return response
                })
        },
        clone(id, name) {
            return apis.templates.clone(id, name)
        },
        setDefault(id) {
            return apis.templates.setDefault(id)
        },
        remove(id) {
            return apis.templates.remove(id)
        },
    }
}

function removeGuiFields(state) {
    let sections = state.sectionConfigurations.map((x) => {
        const { isEditMode, ...rest } = x
        return rest
    })
    return { ...state, sectionConfigurations: sections }
}
