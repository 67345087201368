import React from "react"
import { getLocalizedText } from "../../shared/locale"

export default function SectionGroup({
    item,
    language = "it",
    getComponent,
    style,
    barcode,
    history,
}) {
    const { name, groupType, sections, widgetStyle } = item

    return (
        <div
            id={`${groupType}-group`}
            className={`section-group`}
            data-testid="section-group"
            style={style}>
            {name ? (
                <div className="section-group-title">
                    <h1 className="h2 mt-3 r-title">
                        {getLocalizedText(name, language)}
                    </h1>
                    <hr />
                </div>
            ) : null}
            <div className="group-sections-container">
                {(sections || []).map((s) =>
                    getComponent(
                        { ...s, widgetStyle },
                        barcode,
                        language,
                        {},
                        history,
                    ),
                )}
            </div>
        </div>
    )
}
