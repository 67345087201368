import React, { useState, useCallback, useEffect } from "react"
import { withRouter } from "react-router"
import useFormist from "react-formist"
import * as yup from "yup"
import Wizard from "../shared/components/wizard"
import TextField from "../shared/components/textfield"
import { MultiSelectField, SelectField } from "../shared/components/selectfield"
import { mkApi } from "./api"
import { routes } from "../../shared/routing"
import { getItem, removeItems, setItem } from "./storage"
import { useAsync } from "react-async"
import Spinner from "@atlaskit/spinner"
import { analysisTypes } from "../../analysisTypes"
import { filterBy, toSelectModel } from "./options-utils.js"
import { addCurrent } from "./edit.jsx"

const empty = {
    barcode: "",
    analysisType: "",
    templateIds: [],
    layoutIds: [],
    referral: "",
    sendRules: {
        patient: false,
        referral: false,
    },
}

const schema = yup.object().shape({
    barcode: yup.string().required(),
    analysisType: yup.string().required(),
    templateIds: yup.array().of(yup.string().required()).required(),
    layoutIds: yup.array().of(yup.string().required()).required(),
    referral: yup.string().required(),
})

export const AddForm = ({ history, data, match }) => {
    const [filterReferralForLayouts, setFilterReferralForLayouts] =
        useState(true)
    const toggleReferralFilterForLayouts = useCallback(() => {
        setFilterReferralForLayouts(!filterReferralForLayouts)
    }, [filterReferralForLayouts])

    const [filterReferralForTemplates, setFilterReferralForTemplates] =
        useState(true)
    const toggleReferralFilterForTemplates = useCallback(() => {
        setFilterReferralForTemplates(!filterReferralForTemplates)
    }, [filterReferralForTemplates])

    const [templates, layouts, referrals] = data
    const barcode = match.params.id || ""
    const editMode = match.params.mode === "edit"
    const geneticAnalysis = getItem(barcode) || {}

    const initialData = {
        ...empty,
        barcode,
        analysisType: editMode ? match.params.analysisType : undefined,
        ...geneticAnalysis,
        analyzed: editMode,
    }

    const formist = useFormist(initialData, {
        schema,
        onSubmit: (geneticAnalysis) => {
            setItem(geneticAnalysis.barcode, geneticAnalysis)
            setItem("templates", {
                items: templates.items.map((t) => ({
                    id: t.id,
                    name: t.name,
                })),
            })
            setItem("layouts", {
                items: layouts.items.map((l) => ({
                    id: l.id,
                    name: l.name,
                })),
            })
            setItem("referrals", {
                items: referrals.items.map((r) => ({
                    code: r.code,
                    name: r.name,
                })),
            })
            history.push(
                routes.geneticAnalyses.registerPatient.buildUrl(
                    geneticAnalysis.barcode,
                ),
            )
        },
    })

    const referralsOptions = toSelectModel(referrals.items, (x) => x.code)
    const templateOptions = addCurrent(
        toSelectModel(
            filterBy(
                templates.items,
                formist.values.analysisType,
                formist.values.analysisSubType || "Unknown",
                filterReferralForTemplates ? formist.values.referral : "",
                true,
            ),
        ),
        formist.values.templateIds,
        templates.items,
    )

    const layoutOptions = addCurrent(
        toSelectModel(
            filterBy(
                layouts.items,
                formist.values.analysisType,
                formist.values.analysisSubType || "Unknown",
                filterReferralForLayouts ? formist.values.referral : "",
            ),
        ),
        formist.values.layoutIds,
        layouts.items,
    )

    const onUndo = () => {
        history.push(routes.dashboard.index.url)
    }

    const updateBarCode = (e) => {
        if (e.target.value && e.target.value !== barcode) {
            removeItems(barcode)
        }
        return formist.getFieldProps("barcode").onChange(e)
    }

    const currentAnalysisType = analysisTypes.find(
        (a) => a.value === formist.getFieldProps("analysisType").value,
    )

    useEffect(() => {
        if (
            (
                analysisTypes.find(
                    (a) =>
                        a.value === formist.getFieldProps("analysisType").value,
                )?.subTypes || []
            )
                .map((s) => s.value)
                .indexOf(formist.values.analysisSubType) === -1
        )
            formist.change("analysisSubType", "Unknown")
    }, [formist.values.analysisType])

    const analysisSubTypes = [
        {
            label: " - None - ",
            value: "Unknown",
        },
        ...(currentAnalysisType?.subTypes || []),
    ]

    return (
        <Wizard
            title="Register Analysis"
            buttons={["undo", "submit"]}
            onUndo={onUndo}
            onPrevious={() => history.goBack()}
            {...formist.getFormProps()}>
            <div className="form-row">
                <TextField
                    label="Barcode"
                    width="col"
                    readOnly={editMode}
                    {...formist.getFieldProps("barcode")}
                    onChange={updateBarCode}
                />
            </div>
            <div className="form-row">
                <SelectField
                    label="Analysis Type"
                    width="col"
                    options={analysisTypes}
                    {...formist.getFieldProps("analysisType")}
                />

                <SelectField
                    label="Analysis Sub Type"
                    width="col"
                    options={analysisSubTypes}
                    disabled={analysisSubTypes.length <= 1}
                    {...formist.getFieldProps("analysisSubType")}
                />
            </div>
            <div className="form-row">
                <SelectField
                    label="Referral"
                    width="col"
                    {...formist.getFieldProps("referral")}
                    options={referralsOptions}
                />
            </div>
            <div className="form-row">
                <MultiSelectField
                    label="Templates"
                    width="col"
                    {...formist.getFieldProps("templateIds")}
                    options={templateOptions}
                />
                <div
                    className="fa fa-filter filter-toggle"
                    data-toggle-selected={filterReferralForTemplates}
                    onClick={toggleReferralFilterForTemplates}></div>
            </div>
            <div className="form-row">
                <MultiSelectField
                    label="Layout"
                    width="col"
                    {...formist.getFieldProps("layoutIds")}
                    options={layoutOptions}
                />
                <div
                    className="fa fa-filter filter-toggle"
                    data-toggle-selected={filterReferralForLayouts}
                    onClick={toggleReferralFilterForLayouts}></div>
            </div>
        </Wizard>
    )
}

const loadData = ({ api }) => {
    return Promise.all([
        api.loadTemplates(),
        api.loadLayouts(),
        api.loadReferrals(),
    ])
}

export const AddGeneticAnalysis = ({ history, match }) => {
    const api = mkApi()
    const { data, error, isLoading } = useAsync({
        promiseFn: loadData,
        api,
    })

    if (isLoading) return <Spinner size="xlarge" isCompleting={false} />
    if (error) return `Something went wrong: ${error.message}`
    if (data) return <AddForm history={history} match={match} data={data} />
    return null
}

export default withRouter(AddGeneticAnalysis)
