import React, { useEffect, useState } from "react"
import RichTextEditor from "./rich-text-editor"
import { convertFromRaw, convertToRaw } from "draft-js"
import { CommentAction } from "@atlaskit/comment"
import { SelectField } from "./selectfield"
import { languagesOptions } from "../../../shared/locale"

const RichTextEditorField = ({ value, error, onChange, onSave, onCancel }) => {
    const [content, setContent] = useState(() =>
        convertFromRaw(JSON.parse(value.replaceAll("\n", ""))),
    )
    useEffect(() => {
        setContent(convertFromRaw(JSON.parse(value.replaceAll("\n", ""))))
    }, [value])
    const onChangeInner = (changed) => {
        setContent(changed)
    }
    function save(e) {
        const value = JSON.stringify(convertToRaw(content))
        const ec = {
            target: { value },
        }
        onChange(ec)
        onSave(e, value)
    }
    return (
        <>
            <RichTextEditor content={content} onChange={onChangeInner} />
            <small className="text-danger">{error}</small>
            <div className="form-row section-edit-actions">
                <CommentAction key="1" onClick={save}>
                    Save
                </CommentAction>
                &nbsp;
                <CommentAction key="2" onClick={onCancel}>
                    Cancel
                </CommentAction>
            </div>
        </>
    )
}

export const MultiLanguageRichTextEditorField = ({
    value,
    error,
    onChange,
    onSave,
    onCancel,
}) => {
    const [content, setContent] = useState(() => ({
        it: convertFromRaw(JSON.parse(value.it.replaceAll("\n", ""))),
        eng: convertFromRaw(JSON.parse(value.eng.replaceAll("\n", ""))),
    }))

    useEffect(() => {
        setContent({
            it: convertFromRaw(JSON.parse(value.it.replaceAll("\n", ""))),
            eng: convertFromRaw(JSON.parse(value.eng.replaceAll("\n", ""))),
        })
    }, [value])

    const onChangeInner = (changed, language) => {
        setContent((c) => ({ ...c, [language]: changed }))
    }

    function save(e) {
        const value = {
            it: JSON.stringify(convertToRaw(content.it)),
            eng: JSON.stringify(convertToRaw(content.eng)),
        }
        const ec = {
            target: { value },
        }
        onChange(ec)
        onSave(e, value)
    }

    const [currentLanguage, setCurrentLanguage] = useState("it")

    return (
        <>
            <div className="form-row col-2">
                <SelectField
                    label="Language"
                    width="col"
                    options={languagesOptions}
                    value={currentLanguage}
                    onChange={(e) => setCurrentLanguage(e.target.value)}
                />
            </div>

            <div
                className="form-row"
                style={{
                    visibility: currentLanguage === "it" ? "visible" : "hidden",
                    height: currentLanguage === "it" ? "auto" : "0",
                }}>
                <RichTextEditor
                    content={content.it}
                    onChange={(c) => onChangeInner(c, "it")}
                />
            </div>
            <div
                className="form-row"
                style={{
                    visibility:
                        currentLanguage === "eng" ? "visible" : "hidden",
                    height: currentLanguage === "eng" ? "auto" : "0",
                }}>
                <RichTextEditor
                    content={content.eng}
                    onChange={(c) => onChangeInner(c, "eng")}
                />
            </div>
            <small className="text-danger">{error}</small>
            <div className="form-row section-edit-actions">
                <CommentAction key="1" onClick={save}>
                    Save
                </CommentAction>
                &nbsp;
                <CommentAction key="2" onClick={onCancel}>
                    Cancel
                </CommentAction>
            </div>
        </>
    )
}

export default RichTextEditorField
