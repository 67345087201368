export const locales = [
    {
        language: "it",
        label: "Italiano",
    },
    {
        language: "eng",
        label: "English",
    },
]

export const languagesOptions = locales.map((l) => ({
    value: l.language,
    label: l.label,
}))

export const defaultLanguage = "it"

export const localesSeparator = "#"

export function getLocalizedText(text = "", language = "it") {
    return getTranslations(text)[language]
}

export function getLocalizedValues(text) {
    if (text.indexOf("#") === -1) {
        return locales.map(() => text.replace(/UNICODE-([0-9A-F]+)/g, "&#x$1;"))
    }
    return text
        .split(localesSeparator)
        .map((s) => s.replace(/UNICODE-([0-9A-F]+)/g, "&#x$1;"))
}

export function getTranslations(text) {
    const localized = getLocalizedValues(text)
    return locales.reduce(
        (acc, cur, idx) => ({
            ...acc,
            [cur.language]: localized[idx] || "",
        }),
        {},
    )
}

export function updateTranslation(value, language, values) {
    return locales
        .map((locale) =>
            locale.language === language ? value : values[locale.language],
        )
        .join(localesSeparator)
}
