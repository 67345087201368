import React, { useEffect, useState } from "react"
import Filter from "./done-filter"
import { routes } from "../../shared/routing"
import { Link } from "react-router-dom"
import PaginatedTable from "../shared/components/paginated-table"
import { range } from "lodash"
import { useStateContext, withStateContext } from "../../shared/state-context"
import { initialState, reducer } from "./done-store"
import { mkApi } from "./api"
import { toIta } from "../../shared/dates"
import { success } from "../../shared/notifications"
import StatusLink from "../shared/components/status-link"
import PDFMenu from "../dashboard/pdf-menu"
import { showError } from "../dashboard"
import { download } from "../../shared/ajax"
import { hasPDF, useInProgress } from "../dashboard/pdf-link"
import EmailMenu from "../dashboard/email-menu"
import DropdownMenu, { DropdownItemGroup } from "@atlaskit/dropdown-menu"
import {
    recreatePDFMenuItem,
    removeBarcodeMenuItem,
} from "../dashboard/actions"
import RemoveBarcode from "../dashboard/remove-barcode"
import { useSecurity } from "../../shared/security/security-context"

const head = {
    cells: [
        {
            key: "barcode",
            content: "Barcode",
        },
        {
            key: "patient",
            content: "Patient",
        },
        {
            key: "doneDate",
            content: "Done date",
        },
        {
            key: "reporter",
            content: "Reporter",
        },
        {
            key: "report-show",
            content: "Report",
        },
        { key: "pdf", content: "PDF" },
        { key: "email", content: "" },
        { key: "actions", content: "" },
    ],
}

function Done() {
    const [downloading, setDownloading] = useState(null)
    const [removingBarcode, setRemovingBarcode] = useState(null)
    const [barcodeData, setBarcodeData] = useState(null)
    const [layouts, setLayouts] = useState([])
    const [reporters, setReporters] = useState([])
    const { state, dispatch } = useStateContext()
    const api = mkApi(dispatch)
    const [, , user] = useSecurity()
    const {
        analyses = [],
        isLoading,
        pageNumber,
        totalPages,
        totalItems,
        reporter,
        type,
        referral,
        patient,
        from,
        pattern,
        error,
    } = state

    const fetchData = ({
        page = pageNumber,
        reporterFilter = reporter,
        typeFilter = type,
        referralFilter = referral,
        patientFilter = patient,
        fromFilter = from,
        search = pattern,
    } = {}) => {
        api.loadDone({
            pageNumber: page,
            sortBy: "",
            reporter: reporterFilter,
            type: typeFilter,
            referral: referralFilter,
            patient: patientFilter,
            from: fromFilter,
            pattern: search.length >= 2 ? search : null,
        })
    }

    useEffect(() => {
        api.loadReporters().then((l) => {
            setReporters(l.items)
            if (user?.email && l.items.find((r) => r.email === user.email)) {
                onFilter("reporter", user.email)
            } else {
                fetchData()
            }
        })
        api.loadLayouts().then((l) => setLayouts(l.items))
    }, [])

    useInProgress(analyses, () =>
        fetchData({
            page: pageNumber,
            search: pattern,
            reporterFilter: reporter,
            typeFilter: type,
            referralFilter: referral,
            patientFilter: patient,
            fromFilter: from,
        }),
    )

    function onFilter(filterType, value) {
        switch (filterType) {
            case "from":
                fetchData({
                    page: 0,
                    fromFilter: value,
                })
                break

            case "reporter":
                fetchData({
                    page: 0,
                    reporterFilter: value,
                })
                break
            case "type":
                fetchData({
                    page: 0,
                    typeFilter: value,
                })
                break
            case "referral":
                fetchData({
                    page: 0,
                    referralFilter: value,
                })
                break
            case "patient":
                fetchData({
                    page: 0,
                    patientFilter: value,
                })
                break
            default:
                break
        }
    }

    const onChangePage = (e, page) => {
        fetchData({ page })
    }

    const onChangeSearch = (e) => {
        fetchData({ page: 0, search: e.target.value })
    }

    function createPDF(barcode, layout) {
        api.pdf(barcode, layout)
            .then(() => {
                success("PDF is under construction...")
                fetchData({})
            })
            .catch((e) => {
                showError(e)
            })
    }

    function downloadPDF(barcode, layout, reportMode) {
        setDownloading(barcode)
        const url = api.downloadPDF(barcode, layout, reportMode)
        download(url, barcode, "pdf")
            .catch((e) => showError(e))
            .finally(() => setDownloading(null))
    }

    function sendEmail(barcode, layout, to, callback) {
        api.sendEmail(barcode, layout, to)
            .then(() => {
                success("Report successfully sent by email!")
            })
            .catch((e) => {
                showError(e)
            })
            .finally(() => {
                if (callback) callback()
                fetchData({})
            })
    }

    const recreatePDFHandler = (item) => (e) => {
        e.preventDefault()
        api.allpdf(item.barcode)
            .then(() => {
                success("PDF is under construction...")
                fetchData({})
            })
            .catch((e) => {
                error(e.Message)
            })
    }

    const removeBarcodeHandler = (item) => (e) => {
        e.preventDefault()
        setRemovingBarcode(item.barcode)
    }

    const cancelRemoveBarcode = () => {
        setBarcodeData(null)
        setRemovingBarcode(null)
    }

    const confirmRemoveBarcode = () => {
        api.removeBarcode(removingBarcode, !!barcodeData)
            .then((result) => {
                if (
                    !barcodeData &&
                    (result.patientData ||
                        result.dnaAnalysis ||
                        result.macroBiotaAnalysis)
                ) {
                    setBarcodeData(result)
                } else {
                    success("Barcode removed!")
                    cancelRemoveBarcode()
                    fetchData({})
                }
            })
            .catch((e) => {
                showError(e)
            })
    }

    const mkRows = (items) => {
        return items.map((item, index) => {
            const sendableLayouts = (item.layoutIds || []).filter((l) =>
                hasPDF(item, l),
            )
            return {
                key: `row-${index}-${item.id}`,
                cells: [
                    {
                        content: (
                            <Link
                                to={{
                                    pathname:
                                        routes.geneticAnalyses.edit.buildUrl(
                                            item.barcode,
                                        ),
                                }}>
                                {item.barcode}
                            </Link>
                        ),
                    },
                    {
                        content: `${item.patientFirstName || ""} ${
                            item.patientLastName || ""
                        }`,
                    },
                    {
                        content: toIta(item.doneDate),
                    },
                    {
                        content: item.reporterName,
                    },
                    {
                        content: (
                            <>
                                <div>
                                    <StatusLink
                                        item={item}
                                        expectedStatus="analysed"
                                        linkText="Show"
                                        to={{
                                            pathname:
                                                routes.reports.show.buildUrl(
                                                    item.barcode,
                                                ),
                                        }}
                                    />
                                </div>
                            </>
                        ),
                    },
                    {
                        content: (
                            <PDFMenu
                                layouts={item.layoutIds}
                                allLayouts={layouts}
                                barcode={item.barcode}
                                status={item.status}
                                pdf={item.pdf}
                                downloading={downloading}
                                onCreate={createPDF}
                                onDownload={downloadPDF}
                            />
                        ),
                    },
                    {
                        content: (
                            <EmailMenu
                                layouts={sendableLayouts}
                                allLayouts={layouts}
                                barcode={item.barcode}
                                emails={item.reportEmails}
                                rules={item.sendRules}
                                onSend={sendEmail}
                            />
                        ),
                    },
                    {
                        content: (
                            <DropdownMenu
                                trigger="Actions"
                                triggerType="button">
                                <DropdownItemGroup>
                                    {recreatePDFMenuItem(
                                        item,
                                        recreatePDFHandler,
                                    )}
                                    {removeBarcodeMenuItem(
                                        item,
                                        removeBarcodeHandler,
                                    )}
                                </DropdownItemGroup>
                            </DropdownMenu>
                        ),
                    },
                ],
            }
        })
    }

    const removeBarcodeDataList = (
        barcodeData
            ? [
                  barcodeData.patientData
                      ? "Patient description, diseases, drugs"
                      : "",
                  barcodeData.dnaAnalysis ? "DNA analyses results" : "",
                  barcodeData.macroBiotaAnalysis
                      ? "Macrobiota analyses results"
                      : "",
              ]
            : []
    )
        .filter((w) => w !== "")
        .join(", ")

    const removeBarcodeWarning = barcodeData
        ? `Barcode ${removingBarcode} has some data associated (${removeBarcodeDataList}). Please confirm you are aware that this data is going to be removed too!`
        : `Are you sure you want to remove the barcode ${removingBarcode}?`

    const pages = range(1, totalPages + 1)
    return (
        <>
            <div className="mt-4">
                <Filter
                    reporter={reporter}
                    type={type}
                    referral={referral}
                    patient={patient}
                    from={from}
                    onFilter={onFilter}
                    onChangeSearch={onChangeSearch}
                    totalItems={totalItems}
                    loadPatients={(pattern) => api.loadPatients(pattern)}
                    loadReferrals={(pattern) => api.loadReferrals(pattern)}
                    reporters={reporters}
                />
            </div>
            <div className="row">
                <PaginatedTable
                    type="reporter-done"
                    head={head}
                    rows={mkRows(analyses)}
                    isLoading={isLoading}
                    pageNumber={pageNumber || 1}
                    pages={pages}
                    onChangePage={onChangePage}
                />
            </div>
            {error && (
                <div className="alert alert-danger mt-5">{error.Message}</div>
            )}
            <RemoveBarcode
                enabled={removingBarcode}
                onConfirm={confirmRemoveBarcode}
                onCancel={cancelRemoveBarcode}
                warningMessage={removeBarcodeWarning}
            />
        </>
    )
}

export default withStateContext(Done, initialState, reducer)
