import React from "react"
import "./gauge.scss"
import { getImages } from "../../../shared/images"

const images = getImages(
    import.meta.glob("../../../gauges/*.png", {
        eager: true,
        query: "?url",
        import: "default",
    }),
    "../../../gauges/",
)

function getAngle(value, maxValue, offsetAngle, fullAngle) {
    return (value / maxValue) * fullAngle + offsetAngle
}

export function polarToCartesian(center, radius, angleInDegrees) {
    var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0

    return {
        x: center.x + radius * Math.cos(angleInRadians),
        y: center.y + radius * Math.sin(angleInRadians),
    }
}

function arcGap(center, externalRadius, internalRadius, startAngle, endAngle) {
    const end = polarToCartesian(center, externalRadius, endAngle)
    const endInternal = polarToCartesian(center, internalRadius, endAngle)

    return ["M", end.x, end.y, "L", endInternal.x, endInternal.y].join(" ")
}

function describeArc(
    center,
    externalRadius,
    internalRadius,
    startAngle,
    endAngle,
) {
    const start = polarToCartesian(center, externalRadius, endAngle)
    const end = polarToCartesian(center, externalRadius, startAngle)

    const startInternal = polarToCartesian(center, internalRadius, startAngle)
    const endInternal = polarToCartesian(center, internalRadius, endAngle)

    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1"

    return [
        "M",
        start.x,
        start.y,
        "A",
        externalRadius,
        externalRadius,
        0,
        largeArcFlag,
        0,
        end.x,
        end.y,
        "L",
        startInternal.x,
        startInternal.y,
        "A",
        internalRadius,
        internalRadius,
        0,
        largeArcFlag,
        1,
        endInternal.x,
        endInternal.y,
        "Z",
    ].join(" ")
}

export const Sector = ({
    name,
    center,
    radius,
    size,
    start,
    end,
    gapLabel,
    gapLabelSize = 8,
}) => {
    const gapLabelPos = polarToCartesian(center, radius + gapLabelSize, end)
    return (
        <>
            <path
                className={`sector-${name}`}
                d={describeArc(center, radius, radius - size, start, end)}
            />
            {gapLabel ? (
                <>
                    <path
                        className={`sector-${name}-gap gap-line`}
                        d={arcGap(center, radius, radius - size, start, end)}
                    />
                    <circle
                        className="sector-${name}-gap-label-container gap-label-container"
                        cx={gapLabelPos.x}
                        cy={gapLabelPos.y}
                        r={gapLabelSize}
                    />
                    <text
                        className="sector-${name}-gap-label gap-label"
                        transform={getTickText(
                            center,
                            radius + gapLabelSize,
                            end,
                        )}>
                        <tspan x="0" y="0" alignmentBaseline="middle">
                            {gapLabel}
                        </tspan>
                    </text>
                </>
            ) : null}
        </>
    )
}

export const Index = ({ center, angle, radius, size = 12 }) => {
    return (
        <g transform={`rotate(${angle} ${center.x} ${center.y})`}>
            <polygon
                className="index"
                points={`${center.x - size * 0.7} ${center.y} ${
                    center.x + size * 0.7
                } ${center.y} ${center.x} ${center.y - radius}`}
            />
            <circle
                className="index-center"
                cx={center.x}
                cy={center.y}
                r={size}
            />
        </g>
    )
}

function getTick(center, externalRadius, internalRadius, angle) {
    const p1 = polarToCartesian(center, externalRadius, angle)
    const p2 = polarToCartesian(center, internalRadius, angle)

    return ["M", p1.x, p1.y, "L", p2.x, p2.y, "Z"].join(" ")
}

export const Tick = ({ center, angle, radius, size = 3 }) => {
    return (
        <path
            className="tick"
            d={getTick(center, radius, radius + size, angle)}
        />
    )
}

function getTickText(center, radius, angle) {
    const p = polarToCartesian(center, radius, angle)
    return `translate(${p.x} ${p.y})`
}

export const Label = ({ center, angle, radius, label }) => {
    return (
        <text
            className="tick-text"
            transform={getTickText(center, radius + 10, angle)}>
            <tspan x="0" y="0">
                {label}
            </tspan>
        </text>
    )
}

const SimpleGauge = ({
    size,
    center,
    radius,
    sectors,
    sectorSize,
    max,
    start,
    stop,
    gap,
    value,
    ticks,
    subTicks,
    labels,
    gapLabels,
    ticksSize,
    subTicksSize,
    labelsSize,
}) => {
    const externalRadius =
        radius -
        (ticks.length > 0 ? ticksSize : 0) -
        (labels.length > 0 ? labelsSize : 0)
    const internalRadius = externalRadius - sectorSize
    return (
        <svg viewBox={`0 0 ${size} ${size}`}>
            <circle
                className="full-circle"
                cx={center.x}
                cy={center.y}
                r={radius}
            />
            <circle
                className="external-circle"
                cx={center.x}
                cy={center.y}
                r={externalRadius}
            />
            <circle
                className="internal-circle"
                cx={center.x}
                cy={center.y}
                r={internalRadius}
            />
            {sectors.map((s, idx) => (
                <Sector
                    key={idx}
                    name={s.name}
                    center={center}
                    radius={internalRadius}
                    size={sectorSize}
                    gapLabel={idx < gapLabels.length ? gapLabels[idx] : ""}
                    start={getAngle(s.min, max, start, stop - start)}
                    end={
                        getAngle(s.max, max, start, stop - start) -
                        (idx === sectors.length - 1 ? 0 : gap)
                    }
                />
            ))}
            <Index
                center={center}
                radius={externalRadius}
                angle={getAngle(value, max, start, stop - start)}
            />
            {ticks.map((t, idx) => (
                <Tick
                    key={idx}
                    center={center}
                    radius={externalRadius}
                    size={ticksSize}
                    angle={getAngle(t, max, start, stop - start)}
                />
            ))}
            {subTicks.map((t, idx) => (
                <Tick
                    key={idx}
                    center={center}
                    radius={externalRadius}
                    size={subTicksSize}
                    angle={getAngle(t, max, start, stop - start)}
                />
            ))}
            {labels.map((l, idx) => (
                <Label
                    key={idx}
                    center={center}
                    radius={externalRadius + ticksSize}
                    angle={getAngle(l.value, max, start, stop - start)}
                    label={l.text}
                />
            ))}
        </svg>
    )
}

const ClockGauge = ({
    size,
    center,
    radius,
    sectors,
    sectorSize,
    max,
    start,
    stop,
    value,
    ticks,
    subTicks = [],
    labels,
    ticksSize = 25,
    subTicksSize = 10,
}) => {
    const externalRadius = radius - (ticks.length > 0 ? ticksSize : 0)
    const internalRadius = externalRadius - sectorSize
    const image = images["gauge-clock"]
    return (
        <svg viewBox={`0 0 ${size} ${size}`}>
            <circle
                className="external-circle"
                cx={center.x}
                cy={center.y}
                r={radius}
            />
            <circle
                className="internal-circle"
                cx={center.x}
                cy={center.y}
                r={internalRadius - 5}
            />
            {image && <image href={image} className="clock-image" />}
            {sectors.map((s, idx) => (
                <Sector
                    key={idx}
                    name={s.name}
                    center={center}
                    radius={radius}
                    size={sectorSize}
                    start={getAngle(s.min, max, start, stop - start)}
                    end={getAngle(s.max, max, start, stop - start)}
                />
            ))}
            <Index
                center={center}
                radius={externalRadius}
                angle={getAngle(value, max, start, stop - start)}
            />
            {ticks.map((t, idx) => (
                <Tick
                    key={idx}
                    center={center}
                    radius={externalRadius}
                    size={ticksSize}
                    angle={getAngle(t, max, start, stop - start)}
                />
            ))}
            {subTicks.map((t, idx) => (
                <Tick
                    key={idx}
                    center={center}
                    radius={externalRadius + 6}
                    size={subTicksSize}
                    angle={getAngle(t, max, start, stop - start)}
                />
            ))}
            {labels.map((l, idx) => (
                <Label
                    key={idx}
                    center={center}
                    radius={internalRadius - 3}
                    angle={getAngle(l.value, max, start, stop - start)}
                    label={l.text}
                />
            ))}
        </svg>
    )
}

export const Gauge = ({
    className = "",
    id = "gauge",
    style = {},
    size = 220,
    sectorSize = 14,
    ticks = [],
    subTicks = [],
    ticksSize = 5,
    subTicksSize = 1,
    labels = [],
    labelsSize = 18,
    gapLabels = [],
    sectors = [],
    start = -135,
    stop = 135,
    max = 100,
    gap = 1,
    value = 0,
    valueLabel = "",
    range = "",
    gaugeStyle = "simple",
}) => {
    const center = { x: size / 2.0, y: size / 2.0 }
    const radius = size / 2.0
    return (
        <div id={id} className={`dynamicgauge ${className}`} style={style}>
            {gaugeStyle === "clock" ? (
                <ClockGauge
                    size={size}
                    center={center}
                    radius={radius}
                    sectors={sectors}
                    sectorSize={sectorSize}
                    max={max}
                    start={start}
                    stop={stop}
                    value={value}
                    ticks={ticks}
                    subTicks={subTicks}
                    labels={labels}
                    ticksSize={ticksSize}
                    subTicksSize={subTicksSize}
                />
            ) : (
                <SimpleGauge
                    size={size}
                    center={center}
                    radius={radius}
                    sectors={sectors}
                    sectorSize={sectorSize}
                    max={max}
                    start={start}
                    stop={stop}
                    gap={gap}
                    gapLabels={gapLabels}
                    value={value}
                    ticks={ticks}
                    subTicks={subTicks}
                    labels={labels}
                    ticksSize={ticksSize}
                    subTicksSize={subTicksSize}
                    labelsSize={labelsSize}
                />
            )}

            {valueLabel ? (
                <p className={`value-label value-label-${range}`}>
                    {valueLabel}
                </p>
            ) : null}
        </div>
    )
}

export default Gauge
