import React, { useCallback, useRef, useState } from "react"
import { is } from "immutable"
import RichTextEditor from "../shared/components/rich-text-editor"
import DefaultFormButtonBar from "../shared/components/default-form-button-bar"
import { convertFromRaw, convertToRaw } from "draft-js"
import { throttle } from "lodash"
import { useDirtyStore } from "./dirty-notifier"
import { getLocalizedText } from "../../shared/locale"
import Button from "@atlaskit/button"
import Form from "../shared/components/form"
import ModalDialog from "@atlaskit/modal-dialog"
import { locales } from "../../shared/locale"
import { error } from "../../shared/notifications"

function toLanguageLabel(language) {
    return locales.find((l) => l.language === language)?.label || language
}

export const EMPTY_CONTENT =
    '{"blocks":[{"key":"523bk","text":"Modifica questo testo o cancellalo dal report","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}'

const SectionFreetext = ({
    item,
    readOnly,
    onSave,
    language,
    autoSave = false,
    style = {},
    disabled = false,
    withReset = false,
    onReset = () => {},
}) => {
    const { id, name, value = EMPTY_CONTENT, description = "" } = item
    const [revision, setRevision] = useState(1)
    const content = convertFromRaw(JSON.parse(value.replaceAll("\n", "")))
    const updatedContent = useRef(content)
    const dirty = useRef(false)
    const onDirty = useDirtyStore((state) => state.setDirty)

    const batchSave = useCallback(
        throttle(
            (content) => {
                onSave(
                    {
                        ...item,
                        value: JSON.stringify(convertToRaw(content)),
                    },
                    true,
                    () => {
                        dirty.current = false
                        onDirty(id, false)
                    },
                )
            },
            1000,
            {
                leading: false,
                trailing: true,
            },
        ),
        [id, revision],
    )

    const onChange = (content) => {
        const oldContent = updatedContent.current
        updatedContent.current = content
        if (!is(content, oldContent)) {
            if (!dirty.current) {
                dirty.current = true
                onDirty(id, true)
            }
            if (autoSave) {
                batchSave.cancel()
                batchSave(content)
            }
        }
    }

    const onSaveHandler = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault()
        }
        onSave({
            ...item,
            value: JSON.stringify(convertToRaw(updatedContent.current)),
        })
        dirty.current = false
        onDirty(id, false)
    }

    const bottomButtonsBar = () => {
        if (!readOnly && !autoSave) {
            return (
                <DefaultFormButtonBar
                    primaryText="Save"
                    onSubmit={onSaveHandler}
                    onCancel={() => {}}
                />
            )
        }
        // else render nothing
    }

    const [confirmReset, setConfirmReset] = useState(null)

    function resetToContent(language) {
        setConfirmReset(language)
    }

    function confirmResetToContent() {
        const language = confirmReset
        onReset(
            item,
            language,
            (resp) => {
                const value = convertFromRaw(
                    JSON.parse(resp.content.replaceAll("\n", "")),
                )
                onChange(value)
                setRevision((r) => r + 1)
                setConfirmReset(null)
            },
            (err) => {
                error("Error resetting to default text", err)
                setConfirmReset(null)
            },
        )
    }

    const topButtonsBar = () => {
        if (!readOnly && withReset) {
            return (
                <div
                    className="row"
                    style={{ gap: 10, marginBottom: 10, marginLeft: 10 }}>
                    {locales.map((l) => (
                        <Button
                            key={l.language}
                            appearance="primary"
                            onClick={() => resetToContent(l.language)}>
                            Default text {l.label}
                        </Button>
                    ))}
                </div>
            )
        }
        // else render nothing
    }

    return (
        <div
            className="section-freetext"
            data-testid="section-freetext"
            style={style}>
            <div>
                <h1 className="h2 mt-3 r-title">
                    {getLocalizedText(name, language)}
                </h1>
            </div>
            {description ? (
                <p>{getLocalizedText(description, language)}</p>
            ) : null}
            {topButtonsBar()}
            <RichTextEditor
                disabled={disabled}
                content={updatedContent.current}
                onChange={onChange}
                onSave={autoSave ? null : onSaveHandler}
                readOnly={readOnly}
                revision={revision}
            />
            {confirmReset ? (
                <ModalDialog
                    id="confirm-reset-dialog"
                    heading={`Reset to ${toLanguageLabel(confirmReset)}`}>
                    <Form
                        title=""
                        size="full"
                        buttons={[
                            <Button
                                key="confirm"
                                appearance="primary"
                                onClick={confirmResetToContent}>
                                Confirm
                            </Button>,
                            "cancel",
                        ]}
                        primaryText="Remove"
                        onCancel={() => setConfirmReset(null)}>
                        <div className="form-row">
                            Are you sure you want to reset to default text?
                        </div>
                    </Form>
                </ModalDialog>
            ) : null}
            {bottomButtonsBar()}
        </div>
    )
}

export default SectionFreetext
