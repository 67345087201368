import * as yup from "yup"

export const referralSchema = yup.object().shape(
    {
        code: yup.string().required(),
        name: yup.string().required(),
        email: yup.string().email().required(),
        pec: yup.string().email().optional().nullable(),
        fiscalCode: yup.string().optional().nullable(),
        phone: yup.string().optional().nullable(),
        address: yup.string().optional().nullable(),
        city: yup.string().optional().nullable(),
        province: yup.string().optional().nullable(),
        cap: yup
            .string()
            .optional()
            .nullable()
            .when("cap", {
                is: (value) => value?.length,
                then: (rule) => rule.length(5),
            }),
        piva: yup
            .string()
            .optional()
            .nullable()
            .when("piva", {
                is: (value) => value?.length,
                then: (rule) => rule.length(11),
            }),
        sdi: yup
            .string()
            .optional()
            .nullable()
            .when("sdi", {
                is: (value) => value?.length,
                then: (rule) => rule.length(7),
            }),
        sendRules: yup.object().shape({
            patient: yup.boolean().required(),
            referral: yup.boolean().required(),
        }),
        attachmentRules: yup.object().shape({
            referral: yup.string().required().oneOf(["Complete", "Summary"]),
            patient: yup.string().required().oneOf(["Complete", "Summary"]),
        }),
        customizations: yup.array().of(
            yup.object().shape({
                template: yup.string().required(),
                sectionsFreetext: yup
                    .array()
                    .of(
                        yup.object().shape({
                            id: yup.string().required(),
                            content: yup.object().required().shape({
                                it: yup.string().required(),
                                eng: yup.string().required(),
                            }),
                        }),
                    )
                    .required(),
                removedSections: yup.array().of(yup.string()).required(),
            }),
        ),
    },
    [
        ["cap", "cap"],
        ["piva", "piva"],
        ["sdi", "sdi"],
    ],
)
