// NOTE: manually keep the list sorted by name
export const analysisTypes = [
    {
        label: "Epigene",
        value: "Epigene",
        completionInDays: 15,
        subTypes: [
            { label: "Hormonal", value: "Hormonal" },
            { label: "Longevity", value: "Longevity" },
            { label: "Wellness", value: "Wellness" },
            { label: "Crono", value: "Crono" },
            { label: "Overtraining", value: "Overtraining" },
            { label: "Inflammaging", value: "Inflammaging" },
        ],
    },
    { label: "Genehome", value: "DNA", completionInDays: 30, subTypes: [] },
    {
        label: "Symbyo Dermal",
        value: "Dermal",
        completionInDays: 30,
        subTypes: [],
    },
    {
        label: "Symbyo GUT",
        value: "MacroBiota",
        completionInDays: 30,
        subTypes: [
            { label: "Gut", value: "Gut" },
            { label: "All Gut", value: "AllGut" },
        ],
    },
    { label: "Symbyo Oral", value: "Oral", completionInDays: 30, subTypes: [] },
    {
        label: "Symbyo Semen",
        value: "Semen",
        completionInDays: 30,
        subTypes: [],
    },
    {
        label: "Symbyo Urine",
        value: "Urine",
        completionInDays: 30,
        subTypes: [],
    },
    {
        label: "Symbyo Vaginal",
        value: "Vaginal",
        completionInDays: 30,
        subTypes: [],
    },
]

export function completionInDays(type) {
    return lookup(type).completionInDays
}

export function lookup(type) {
    return (
        analysisTypes.find((x) => x.value === type) || {
            label: "NP",
            value: "NP",
            completionInDays: 30,
        }
    )
}
