export const toSelectModel = (items, getId = (x) => x.id) =>
    items.map((x) => {
        return {
            value: getId(x),
            label: x.name,
        }
    })

const byAnalysisType = (value) => {
    return (x) => x.analysisType === value
}

const byAnalysisSubType = (value) => {
    return (x) =>
        value === "Unknown" ||
        x.analysisSubType === value ||
        x.analysisSubType === "Unknown"
}

const byReferral = (value, includeUnset) => {
    return (x) =>
        x.defaultTemplate ||
        x.defaultLayout ||
        (x.referralCodes.length === 0 && includeUnset) ||
        (value ? x.referralCodes.includes(value) : true)
}

export const filterBy = (
    items,
    analysisType,
    analysisSubType,
    referral,
    includeUnset,
) => {
    return items
        .filter(byAnalysisType(analysisType))
        .filter(byAnalysisSubType(analysisSubType))
        .filter(byReferral(referral, includeUnset))
}
