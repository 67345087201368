import React, { useCallback, useEffect, useState } from "react"
import Form from "../shared/components/form"
import TextField from "../shared/components/textfield"
import useFormist from "react-formist"
import Tabs from "@atlaskit/tabs"
import CheckField from "../shared/components/checkfield"
import RadioCheckList from "../shared/components/radio-check-list.jsx"
import { SelectField } from "../shared/components/selectfield.jsx"
import { analysisTypes } from "../../analysisTypes"
import { getLocalizedText, languagesOptions } from "../../shared/locale.js"
import SectionFreetext from "../reports/section-free-text.jsx"
import DirtyNotifier from "../reports/dirty-notifier.jsx"
import Button from "@atlaskit/button"
import { EMPTY_CONTENT } from "../reports/section-free-text.jsx"

const ReferralData = ({ formist, isEdit }) => {
    return (
        <div className="form-container-separator">
            <div className="form-row">
                <TextField
                    label="Code"
                    width="col"
                    readOnly={isEdit}
                    {...formist.getFieldProps("code")}
                />
            </div>
            <div className="form-row">
                <TextField
                    label="Name"
                    width="col"
                    {...formist.getFieldProps("name")}
                />
            </div>
            <div className="form-row">
                <TextField
                    label="Email"
                    width="col"
                    {...formist.getFieldProps("email")}
                />
            </div>
            <div className="form-row">
                <TextField
                    label="Pec"
                    width="col"
                    {...formist.getFieldProps("pec")}
                />
            </div>
            <div className="form-row">
                <TextField
                    label="Fiscal Code"
                    width="col"
                    {...formist.getFieldProps("fiscalCode")}
                />
            </div>
            <div className="form-row">
                <TextField
                    label="Phone"
                    width="col"
                    {...formist.getFieldProps("phone")}
                />
            </div>
            <div className="form-row">
                <TextField
                    label="Address"
                    width="col"
                    {...formist.getFieldProps("address")}
                />
            </div>
            <div className="form-row">
                <TextField
                    label="City"
                    width="col"
                    {...formist.getFieldProps("city")}
                />
            </div>
            <div className="form-row">
                <TextField
                    label="Province"
                    width="col"
                    {...formist.getFieldProps("province")}
                />
            </div>
            <div className="form-row">
                <TextField
                    label="CAP"
                    width="col"
                    {...formist.getFieldProps("cap")}
                />
            </div>
            <div className="form-row">
                <TextField
                    label="PIVA"
                    width="col"
                    {...formist.getFieldProps("piva")}
                />
            </div>
            <div className="form-row">
                <TextField
                    label="SDI"
                    width="col"
                    {...formist.getFieldProps("sdi")}
                />
            </div>
        </div>
    )
}

const SendRules = ({ formist }) => {
    return (
        <>
            <h4>Send To:</h4>
            <div className="form-row">
                <CheckField
                    label="Patient"
                    width="col"
                    {...formist.getFieldProps("sendRules.patient")}
                />
            </div>
            <div className="form-row">
                <CheckField
                    label="Referral"
                    width="col"
                    {...formist.getFieldProps("sendRules.referral")}
                />
            </div>
        </>
    )
}

const SectionEditors = ({
    rules,
    idPrefix = "",
    sections,
    currentSection,
    setAdviceEnabled = () => {},
    saveCustomText = () => {},
    restoreDefaultText = () => {},
    setCurrentSection = () => {},
}) => {
    const [currentLanguage, setCurrentLanguage] = useState("it")
    const tabs = sections.map((s) => ({
        label: (
            <div className="referrals-sections-tab">
                <CheckField
                    value={rules.sections[s.id]?.enabled || false}
                    onChange={(e) => setAdviceEnabled(s.id, e.target.value)}
                />
                <span>{getLocalizedText(s.name)}</span>
                {rules.sections[s.id]?.customText ? (
                    <span className="referrals-sections-status edited"></span>
                ) : null}
            </div>
        ),
        content: (
            <div key={idPrefix + s.id}>
                <div className="form-row">
                    {rules.sections[s.id]?.customText ? (
                        <div style={{ display: "flex", gap: "20px" }}>
                            <span className="referrals-sections-status edited">
                                Custom Text
                            </span>{" "}
                            <Button
                                appearance="primary"
                                onClick={() => restoreDefaultText(s.id)}>
                                Restore default (for all languages)
                            </Button>
                        </div>
                    ) : (
                        <span className="referrals-sections-status notedited">
                            Default Text
                        </span>
                    )}
                </div>
                <SelectField
                    label="Language"
                    width="col"
                    options={languagesOptions}
                    value={currentLanguage}
                    onChange={(e) => setCurrentLanguage(e.target.value)}
                />
                <div
                    className="form-row"
                    style={{
                        visibility:
                            currentLanguage === "it" ? "visible" : "hidden",
                        height: currentLanguage === "it" ? "auto" : "0",
                    }}>
                    <SectionFreetext
                        disabled={!rules.sections[s.id]?.enabled}
                        style={{ flexGrow: 1 }}
                        key={`${s.id}-${rules.sections[s.id]?.customText}`}
                        item={{
                            ...s,
                            value:
                                rules.sections[s.id]?.text["it"] ||
                                s.content["it"] ||
                                EMPTY_CONTENT,
                        }}
                        autoSave
                        onSave={(item, _, callback) =>
                            saveCustomText(item.id, item.value, "it", callback)
                        }
                    />
                </div>
                <div
                    className="form-row"
                    style={{
                        visibility:
                            currentLanguage === "eng" ? "visible" : "hidden",
                        height: currentLanguage === "eng" ? "auto" : "0",
                    }}>
                    <SectionFreetext
                        disabled={!rules.sections[s.id]?.enabled}
                        style={{ flexGrow: 1 }}
                        key={`${s.id}-${rules.sections[s.id]?.customText}`}
                        item={{
                            ...s,
                            value:
                                rules.sections[s.id]?.text["eng"] ||
                                s.content["eng"] ||
                                EMPTY_CONTENT,
                        }}
                        autoSave
                        onSave={(item, _, callback) =>
                            saveCustomText(item.id, item.value, "eng", callback)
                        }
                    />
                </div>
            </div>
        ),
    }))
    return (
        <div className="referrals-sections-tabs">
            <Tabs
                tabs={tabs}
                selected={sections.findIndex((s) => s.id === currentSection)}
                onSelect={(e, idx) => setCurrentSection(sections[idx].id)}
            />
        </div>
    )
}

function rulesToCustomizations(rules, templates) {
    return templates
        .filter((t) => rules[t.id])
        .reduce((acc, t) => {
            const sections = t.sectionConfigurations.filter(
                (s) => s.type.toLowerCase() === "freetext",
            )
            const removed = rules[t.id].advices
                ? sections.filter((s) => !rules[t.id]?.sections[s.id]?.enabled)
                : sections

            const customized = sections.filter(
                (s) => rules[t.id]?.sections[s.id]?.customText,
            )

            return [
                ...acc,
                {
                    template: t.id,
                    sectionsFreetext: customized.map((s) => ({
                        id: s.id,
                        content: {
                            it:
                                rules[t.id]?.sections[s.id]?.text["it"] ||
                                EMPTY_CONTENT,
                            eng:
                                rules[t.id]?.sections[s.id]?.text["eng"] ||
                                EMPTY_CONTENT,
                        },
                    })),
                    removedSections: removed.map((s) => s.id),
                },
            ]
        }, [])
}

function customizationsToRules(customizations, templates) {
    return customizations.reduce((acc, c) => {
        const template = templates.find((t) => t.id === c.template)
        if (!template) return acc
        const sections = template.sectionConfigurations.filter(
            (s) => s.type.toLowerCase() === "freetext",
        )
        const removed = c.removedSections.filter((c) =>
            sections.find((s) => s.id === c),
        )
        const customized = c.sectionsFreetext.filter((c) =>
            sections.find((s) => s.id === c.id),
        )
        return {
            ...acc,
            [c.template]: {
                advices: removed.length < sections.length,
                sections: sections.reduce(
                    (accs, s) => ({
                        ...accs,
                        [s.id]: {
                            enabled: !removed.find((r) => r === s.id),
                            customText: !!customized.find((c) => c.id === s.id),
                            text: {
                                it:
                                    customized.find((c) => c.id === s.id)
                                        ?.content["it"] ||
                                    s.content["it"] ||
                                    EMPTY_CONTENT,
                                eng:
                                    customized.find((c) => c.id === s.id)
                                        ?.content["eng"] ||
                                    s.content["eng"] ||
                                    EMPTY_CONTENT,
                            },
                        },
                    }),
                    {},
                ),
            },
        }
    }, {})
}

function createNewTemplateRules(sections) {
    return {
        advices: true,
        sections: sections.reduce(
            (acc, s) => ({
                ...acc,
                [s.id]: {
                    enabled: true,
                    customText: false,
                    text: {
                        it: s.content.it || EMPTY_CONTENT,
                        eng: s.content.eng || EMPTY_CONTENT,
                    },
                },
            }),
            {},
        ),
    }
}

const AdvicesRules = ({ formist, templates = [] }) => {
    const [analysisType, setAnalysisType] = useState(null)
    const [template, setTemplate] = useState(null)
    const [templateRules, setTemplateRules] = useState(
        customizationsToRules(
            formist.getFieldProps("customizations").value,
            templates,
        ),
    )
    const templateOptions = templates
        .filter(
            (t) =>
                t.sectionConfigurations.filter(
                    (s) => s.type.toLowerCase() === "freetext",
                ).length > 0,
        )
        .filter((t) => analysisType === null || t.analysisType === analysisType)
        .map((t) => ({
            label: t.name,
            value: t.id,
        }))

    useEffect(() => {
        setTemplate(null)
    }, [analysisType])

    useEffect(() => {
        formist.change(
            "customizations",
            rulesToCustomizations(templateRules, templates),
        )
    }, [templateRules])

    const currentTemplate = templates.find((t) => t.id === template)
    const sections =
        currentTemplate?.sectionConfigurations.filter(
            (s) => s.type.toLowerCase() === "freetext",
        ) || []
    const currentTemplateRules =
        templateRules[template] || createNewTemplateRules(sections)

    const [currentSection, setCurrentSection] = useState(null)

    useEffect(() => {
        if (sections.length > 0 && !currentSection) {
            setCurrentSection(sections[0].id)
        }
    }, [sections])

    const setAdvices = useCallback(
        (value) => {
            setTemplateRules((old) => {
                const rules = old[template] || createNewTemplateRules(sections)
                return {
                    ...old,
                    [template]: {
                        ...rules,
                        advices: value,
                        sections: Object.keys(rules.sections).reduce(
                            (alls, sid) => ({
                                ...alls,
                                [sid]: {
                                    ...rules.sections[sid],
                                    enabled: value,
                                },
                            }),
                            {},
                        ),
                    },
                }
            })
        },
        [template, sections],
    )

    const setAdviceEnabled = useCallback(
        (id, enabled) => {
            setTemplateRules((old) => {
                const rules = old[template] || createNewTemplateRules(sections)
                return {
                    ...old,
                    [template]: {
                        ...rules,
                        sections: {
                            ...rules.sections,
                            [id]: { ...rules.sections[id], enabled },
                        },
                    },
                }
            })
        },
        [template, sections],
    )

    const saveCustomText = useCallback(
        (id, text, language, callback) => {
            setTemplateRules((old) => {
                const rules = old[template] || createNewTemplateRules(sections)
                return {
                    ...old,
                    [template]: {
                        ...rules,
                        sections: {
                            ...rules.sections,
                            [id]: {
                                ...rules.sections[id],
                                customText: true,
                                text: {
                                    ...rules.sections[id].text,
                                    [language]: text,
                                },
                            },
                        },
                    },
                }
            })
            callback()
        },
        [template, sections],
    )

    const restoreDefaultText = useCallback(
        (id) => {
            setTemplateRules((old) => {
                const rules = old[template] || createNewTemplateRules(sections)
                return {
                    ...old,
                    [template]: {
                        ...rules,
                        sections: {
                            ...rules.sections,
                            [id]: {
                                ...rules.sections[id],
                                customText: false,
                                text: {
                                    it:
                                        sections.find(
                                            (s) => s.id == currentSection,
                                        ).content["it"] || EMPTY_CONTENT,
                                    eng:
                                        sections.find(
                                            (s) => s.id == currentSection,
                                        ).content["eng"] || EMPTY_CONTENT,
                                },
                            },
                        },
                    },
                }
            })
        },
        [template, sections, currentSection],
    )

    return (
        <div className="form-container-separator">
            <div className="form-row">
                <div className="col-4">
                    <SelectField
                        label="Analysis Type"
                        width="col"
                        value={analysisType}
                        onChange={(e) => setAnalysisType(e.target.value)}
                        options={analysisTypes}
                    />
                </div>
                <div className="col-4">
                    <SelectField
                        label="Template"
                        width="col"
                        value={template}
                        onChange={(e) => setTemplate(e.target.value)}
                        options={templateOptions}
                    />
                </div>
                <div className="col-2">
                    {currentTemplate ? (
                        <>
                            <RadioCheckList
                                label="Advices"
                                value={
                                    currentTemplateRules.advices
                                        ? "true"
                                        : "false"
                                }
                                style={{ marginLeft: "0.5rem" }}
                                onChange={(e) => {
                                    setAdvices(e.target.value === "true")
                                }}
                                items={[
                                    {
                                        label: "Yes",
                                        value: "true",
                                    },
                                    {
                                        label: "No",
                                        value: "false",
                                    },
                                ]}
                            />
                        </>
                    ) : null}
                </div>
                <div className="col-4"></div>
            </div>
            {currentTemplate ? (
                <div
                    style={{
                        display: currentTemplateRules?.advices
                            ? "block"
                            : "none",
                    }}>
                    <SectionEditors
                        rules={currentTemplateRules}
                        idPrefix={template}
                        sections={sections}
                        currentSection={currentSection}
                        setCurrentSection={setCurrentSection}
                        setAdviceEnabled={setAdviceEnabled}
                        saveCustomText={saveCustomText}
                        restoreDefaultText={restoreDefaultText}
                    />
                </div>
            ) : null}
        </div>
    )
}

const ReferralRules = ({ formist }) => {
    return (
        <div className="form-container-separator">
            <SendRules formist={formist} />
            {<AttachmentRules formist={formist} />}
        </div>
    )
}
const AttachmentRules = ({ formist }) => {
    const complete = {
        label: "Complete Report",
        value: "Complete",
    }
    // Per ora, il caso d'uso è di avere segnato come "inSummary" solo la sezione freetext di syntesis-esito.
    // Un domani il cliente potrebbe scegliere di inserire altre sezioni freetext
    // Per questo c'è discrepanza tra la label e il value
    const summary = {
        label: "Synthesis Only",
        value: "Summary",
    }
    return (
        <>
            <h4>Attachment:</h4>
            <div className="form-row">
                <RadioCheckList
                    label="Referral"
                    style={{ marginLeft: "0.5rem" }}
                    items={[
                        {
                            label: complete.label,
                            value: complete.value,
                        },
                        {
                            label: summary.label,
                            value: summary.value,
                        },
                    ]}
                    {...formist.getFieldProps("attachmentRules.referral")}
                />
                <RadioCheckList
                    label="Patient"
                    style={{ marginLeft: "0.5rem" }}
                    items={[
                        {
                            label: complete.label,
                            value: complete.value,
                        },
                        {
                            label: summary.label,
                            value: summary.value,
                        },
                    ]}
                    {...formist.getFieldProps("attachmentRules.patient")}
                />
            </div>
        </>
    )
}

export const ReferralFormFields = ({ formist, isEdit = false, templates }) => {
    const [selectedTab, setSelectedTab] = useState(0)
    const tabs = [
        {
            label: "Referral Data",
            content: <ReferralData formist={formist} isEdit={isEdit} />,
        },
        {
            label: "Send Rules",
            content: <ReferralRules formist={formist} templates={templates} />,
        },
        {
            label: "Advices",
            content: <AdvicesRules formist={formist} templates={templates} />,
        },
    ]

    return (
        <Tabs
            tabs={tabs}
            onSelect={(tab, idx) => setSelectedTab(idx)}
            selected={selectedTab}
        />
    )
}

const ReferralForm = ({
    initialValues,
    isEdit,
    schema,
    formProps,
    onSubmit,
    onCancel,
    templates,
}) => {
    const formist = useFormist(initialValues, { schema, onSubmit })
    return (
        <>
            <DirtyNotifier />
            <Form
                buttonsOnTop
                {...formProps}
                {...formist.getFormProps()}
                onCancel={onCancel}>
                <ReferralFormFields
                    formist={formist}
                    isEdit={isEdit}
                    templates={templates}
                />
            </Form>
        </>
    )
}

export default ReferralForm
