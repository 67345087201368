import React, { useState } from "react"
import "./ratio.scss"
import Legend from "../shared/components/legend"
import {
    SummaryRatio,
    getRatioType,
    ChartRatio,
    SliceRatio,
} from "../shared/components/ratio"

const SectionRatio = ({
    item,
    style,
    language,
    editable = false,
    onSave,
    onReset,
}) => {
    const [currentNumerator, setCurrentNumerator] = useState(
        item?.numerator?.value || 0,
    )
    const {
        numerator = { value: 0, name: "" },
        denominator = { value: 0, name: "" },
        rank = "neutral",
        ratioType = "",
        widgetStyle = {},
    } = item

    const legendItems = [
        {
            rank,
            label: numerator.name,
        },
        {
            rank: "neutral",
            label: denominator.name,
        },
    ]
    const [, type] = getRatioType(ratioType)

    function saveValue(value) {
        const newValue = Number(value)
        if (Number.isInteger(newValue) && newValue !== currentNumerator) {
            setCurrentNumerator(newValue)
            onSave({
                ...item,
                numerator: { ...numerator, value },
            })
        }
    }

    function resetValue() {
        onReset(item)
    }

    return (
        <div
            className={`section-ratio section-ratio-${type}`}
            data-testid="section-ratio"
            style={style}>
            {ratioType === "Summary" ? (
                <>
                    <SummaryRatio
                        numerator={numerator}
                        denominator={denominator}
                        language={language}
                        rank={rank}
                        editable={editable}
                        onSave={saveValue}
                        onReset={resetValue}
                        {...widgetStyle}
                    />
                    <Legend items={legendItems} language={language} />
                </>
            ) : ratioType === "Slice" ? (
                <SliceRatio
                    numerator={numerator}
                    denominator={denominator}
                    language={language}
                    rank={rank}
                />
            ) : (
                <ChartRatio
                    {...item}
                    numerator={numerator}
                    denominator={denominator}
                    language={language}
                    editable={editable}
                />
            )}
        </div>
    )
}

export default SectionRatio
